.Footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 30px;
    font-size: 16px;
    padding: 40px 20px;
    background-color: #191919;
    color: white;
}

@media only screen and (max-width: 768px) {
    .Footer {
        font-size: 14px;
    }
}