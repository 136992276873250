.manage-booking {
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    width: 100%;
    /* padding-bottom: 40px; */

    .search-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background-color: white;
        width: 60%;
        border: 1px solid #DDD;
        box-shadow: 0 5px 10px rgba(0, 44, 102, 0.2);
        padding: 15px 20px;
        transform: translateY(-40px);

        .title {
            font-size: 26px;
            font-weight: 700;
            margin-bottom: 25px;
        }

        .info-para {
            color: rgb(92, 92, 92);
            margin-bottom: 30px;
            width: 90%;
            text-align: center;
        }

        .content {
            width: 40%;

            .input-container {
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                width: 100%;
                overflow-x: hidden;

                .label {
                    margin-bottom: 5px;
                    color: rgb(92, 92, 92);
                    width: fit-content;
                }

                .flex-inputs {
                    display: flex;
                    align-items: center;
                    gap: 1px;

                    .select {
                        .ant-select-selector {
                            width: 80px;
                            border: 1px solid rgba(96, 96, 96, 0.639);
                            border-radius: 6px;
                            transition: none;

                            &:focus-within {
                                box-shadow: none;
                                border: 2px solid rgba(0, 0, 0, 0.639);
                            }
                        }
                    }

                    .text-input {
                        flex: 1;
                        width: 100%;
                    }
                }

                .input {
                    padding: 10px 8px;
                    border: 1px solid rgba(96, 96, 96, 0.639);
                    border-radius: 6px;
                    font-size: 16px;

                    &:focus {
                        outline-width: 0;
                        border: 2px solid rgba(0, 0, 0, 0.639);
                    }
                }

                .error {
                    color: rgb(198, 47, 47);
                    font-size: 16px;
                    margin-top: 3px;
                }
            }

            .btn-div {
                width: 100%;
                margin-bottom: 30px;

                .btn {
                    padding: 11px 0;
                    border: none;
                    border-radius: 6px;
                    color: white;
                    background-color: #e67c30;
                    gap: 5px;
                    transition: all 350ms ease-in-out;
                    width: 100%;

                    &:hover {
                        cursor: pointer;
                        background-color: #ff7b06;
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1100px) {
        .search-box {
            .content {
                width: 60%
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .search-box {
            width: 100%;
            transform: translate(0, 0);
            border-radius: 0;
            padding: 15px 0;

            .content {
                width: 90%;
            }
        }
    }

}