.success {
    text-align: center;
    padding: 40px 0;
    background: #f5f5f5;
}

.success .card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    /* margin: 0 auto; */
}

.success .box {
    border-radius: 200px;
    height: 200px;
    width: 200px;
    background: #F8FAF5;
    margin: 0 auto;
}

.success h1 {
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}

.success .color-green {
    color: #88B04B;
}

.success .color-blue {
    color: #2f2b9d;
}

.success p {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin: 0;
}

.success .checkmark {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.success .question-mark {
    color: #2f2b9d;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

@media only screen and (max-width: 768px) {
    .success .card {
        padding: 60px 20px;
    }

    .success h1 {
        font-size: 26px;
    }

    .success .box {
        width: 150px;
        height: 150px;
    }

    .success i {
        line-height: 150px;
        font-size: 70px;
    }
}