.SearchResult {
    width: 100%;
    display: flex;
    justify-content: center;

    .content {
        width: 60%;
        padding: 5px;

        .selected-date {
            display: flex;
            background-color: white;
            border: 1px solid grey;
            border-radius: 6px;
            margin-bottom: 20px;

            .item {
                flex: 1;
                text-align: center;
                border-right: 1px solid grey;
                padding: 12px 0;
            }

            .item:last-child {
                border-right: none;
            }

            .active-item {
                border-bottom: 4px solid rgb(19, 194, 19);
            }

            .disabled-item {
                cursor: default;
                color: rgb(172, 172, 172);
            }

            .non-disabled-item {
                cursor: pointer;

                &:hover {
                    background-color: rgb(222, 222, 222);
                }
            }

        }

        .title {
            font-size: 20px;
            font-weight: 700;
        }

        .result-count {
            width: 100%;
            text-align: right;
            padding: 5px 0;
            margin-bottom: 18px;
        }

        .info-tooltip {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-bottom: 10px;

            .tooltip-text {
                color: rgb(194, 44, 44);
                font-size: 16px;
            }
        }

        .buses {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .bus {
                background-color: white;
                border: 1px solid #e1e1e1;
                border-radius: 6px;
                padding: 10px 20px;
                cursor: pointer;

                .bus-type {
                    background-color: rgb(229, 249, 192);
                    width: fit-content;
                    font-size: 14px;
                    margin-bottom: 6px;
                    padding: 1px 10px;
                    border-radius: 15px;
                }

                .timeline {
                    display: flex;

                    .duration-timeline-item {
                        position: relative;
                        width: 45%;
                        margin: 0 8px;

                        .travel-time {
                            text-align: center;
                        }

                        .travel-time::before,
                        .travel-time::after {
                            content: '';
                            position: absolute;
                            top: 50%;
                            width: calc(50% - 43px);
                            height: 1.5px;
                            background-color: #e1e1e1;
                        }

                        .travel-time::before {
                            left: 0;
                        }

                        .travel-time::after {
                            right: 0;
                        }
                    }

                    .second-duration-timeline-item {
                        width: calc(45% + 16px + 162px);
                        display: flex;
                        justify-content: space-between;
                        font-size: 18px;
                    }

                    .timeline-item {
                        display: flex;
                        flex-direction: column;
                        font-size: 18px;

                        .time {
                            font-weight: bold;
                        }

                        .travel-time {
                            color: #6b6b6b;
                        }

                        .price {
                            font-weight: bold;
                            font-size: 20px;
                        }
                    }

                    .timeline-item:last-child {
                        margin-left: auto;
                    }
                }

                .info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-top: 10px;

                    .info-details {
                        display: flex;
                        align-items: center;
                        gap: 10px;

                        .item {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            color: #5c5c5c;

                            .nested-item {
                                display: flex;
                                align-items: center;
                                gap: 6px;
                            }

                            .seperator {
                                width: 1px;
                                height: 16px;
                                background-color: rgb(181, 181, 181);
                            }
                        }

                        .border-item {
                            padding: 2px 10px;
                            border: 1px solid grey;
                            border-radius: 15px;
                        }
                    }

                    .btn {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        padding: 9px 6px 9px 12px;
                        background-color: #e67c30;
                        border: none;
                        border-radius: 6px;
                        color: white;

                        &:hover {
                            cursor: pointer;
                            background-color: #ff7b06;
                            opacity: 0.8;
                        }

                        div {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .SearchResult {
        .content {
            .buses {
                .bus {
                    .timeline {
                        .duration-timeline-item {
                            width: 33%;
                            margin: 0 8px;
                        }

                        .second-duration-timeline-item {
                            width: calc(33% + 16px + 162px);
                        }

                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 450px) and (max-width: 768px) {
    .SearchResult {
        .content {
            .buses {
                .bus {
                    .timeline {
                        .timeline-item {
                            font-size: 16px;

                            .price {
                                font-size: 18px;
                            }
                        }

                        .duration-timeline-item {
                            width: 33%;
                            margin: 0 8px;
                        }

                        .second-duration-timeline-item {
                            width: calc(33% + 16px + 145px);
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .SearchResult {
        .content {
            .info-tooltip {
                .tooltip-text {
                    font-size: 13px;
                }
            }

            .buses {
                .bus {
                    .timeline {
                        .timeline-item {
                            font-size: 12px;

                            .price {
                                font-size: 14px;
                            }
                        }

                        .duration-timeline-item {
                            width: 33%;
                            margin: 0 4px;

                            .travel-time::before,
                            .travel-time::after {
                                width: calc(50% - 28px);
                            }
                        }

                        .second-duration-timeline-item {
                            width: calc(33% + 8px + 108px);
                            font-size: 12px;
                        }

                    }

                    .info {
                        .info-details {
                            gap: 5px;

                            .item {
                                gap: 4px;
                                font-size: 12px;
                            }
                        }

                        .btn {
                            gap: 5px;
                            padding: 7px 4px 7px 8px;

                            div {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .SearchResult {
        .content {
            width: 100%;

            .buses {
                .bus {
                    padding: 10px;

                    .timeline {
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}