.Home {
    background-color: #f5f5f5;
    /* padding-bottom: 40px; */

    .search-box {
        border-radius: 5px;
        background-color: white;
        margin: 0px 6vw;
        border: 1px solid #DDD;
        box-shadow: 0 5px 10px rgba(0, 44, 102, 0.2);
        padding: 15px 20px;
        transform: translateY(-40px);

        .error {
            color: rgb(198, 47, 47);
            font-size: 16px;
            margin-top: 3px;
        }


        .title {
            font-size: 22px;
            font-weight: 700;
        }


        .flex-row {
            display: flex;
            align-items: flex-start;
            margin-top: 8px;
            flex-wrap: wrap;
            gap: 0.5%;
            row-gap: 10px;

            .flex-row-item {
                display: flex;
                flex-direction: column;
                padding-bottom: 10px;
                width: 19.5%;

                .label {
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    gap: 2px;
                    margin-bottom: 8px;

                    div {
                        font-size: 19px;
                    }
                }

                .text-input {
                    .ant-select-selector {
                        border: 1px solid rgba(152, 152, 152, 0.379);

                        &:focus-within {
                            box-shadow: 0 0 0 1px rgba(30, 30, 30, 0.97);
                        }
                    }
                }

                .date-picker {
                    border: 1px solid rgba(152, 152, 152, 0.379);

                    &:focus-within {
                        box-shadow: 0 0 0 1px rgba(30, 30, 30, 0.97);
                    }
                }

                .search-btn {
                    padding: 12px 70px;
                    border: none;
                    border-radius: 6px;
                    color: white;
                    background-color: #e67c30;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    transition: all 350ms ease-in-out;

                    &:hover {
                        cursor: pointer;
                        background-color: #ff7b06;
                        opacity: 0.8;
                    }

                    div {
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }

            .flex-row-last-item {
                align-self: flex-end;
            }

            .flex-row-center-item {
                align-self: center;
                transform: translateY(3px);
            }
        }
    }

    .main-content {
        padding-bottom: 80px;

        .error-info {
            margin-top: 40px;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: #FF6600;
            background-color: #004AAD;
            padding: 20px 8px;
            border-radius: 5px;
        }

        .comment {
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            padding: 0 20px;
            margin-top: 80px;

            div:first-child {
                font-size: 20px;
            }

            div:last-child {
                font-size: 16px;
            }
        }

        .route-info {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 20px;
            margin-bottom: 20px;
            background-color: #f9f9f9;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            display: flex;
            flex-direction: column;
            align-items: center;

            .text-2 {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
                color: #333;
            }

            .flex-table {
                display: flex;
                gap: 10px;
            }

            .schedule-rows-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 10px;
            }

            .schedule-row {
                display: flex;
                align-items: center;
                width: fit-content;
            }

            .para {
                font-size: 16px;
                color: #555;
            }

            .para-2 {
                font-size: 16px;
                color: #555;
                margin-right: 10px;
            }

            .direction-icon {
                margin: 0 10px;
            }

            svg {
                fill: #e67c30;
            }

            th.ant-table-cell,
            td.ant-table-cell {
                padding: 5px 10px;
            }

            /* .schedule-row:hover {
                background-color: #e6e6e6;
            } */
        }

        .cards-container {
            margin-top: 70px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .cards-title {
                font-size: 2.125rem;
                font-weight: 700;
            }

            .seperator {
                border-bottom: 3px solid #f58426;
                width: 110px;
                margin-top: 30px;
            }

            .cards {
                margin-top: 50px;
                display: flex;
                justify-content: center;
                width: 100%;

                .card {
                    width: 540px;
                    padding: 5px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 20px;

                    img {
                        width: 88%;
                        height: 302px;
                        border-radius: 4px;
                        box-shadow: 10px 10px 0px 1px rgba(0, 0, 0, 0.07);
                    }

                    .title {
                        margin-top: 15px;
                        font-size: 1.125rem;
                        font-weight: 700;
                    }

                    .para {
                        margin: 0;
                        max-width: 86%;
                        text-align: center;
                    }

                    .btn {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        text-decoration: none;
                        background-color: #f58426;
                        border-radius: 30px;
                        margin-top: 7px;
                        padding: 17px 40px;
                        transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;

                        &:hover {
                            background-color: #fd5203;

                            div {
                                color: white;
                            }
                        }

                        div {
                            color: black;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    .absolute-container {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: flex-end;
        position: relative;
        background-image: url('../../assets/absolute-bg.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #191919;
            opacity: 0.6;
            z-index: 1;
        }

        .content {
            position: relative;
            z-index: 2;
            padding-top: 150px;
            width: 45%;
            display: flex;
            flex-direction: column;
            gap: 25px;

            .title {
                font-size: 2.125rem;
                color: white;
                font-weight: 700;
                max-width: 90%;
            }

            .seperator {
                border-bottom: 3px solid #f58426;
                width: 60px;
            }

            .para {
                color: white;
                font-size: 16px;
                line-height: 1.7;
                max-width: 90%;
            }

            .btn {
                display: flex;
                align-items: center;
                gap: 10px;
                text-decoration: none;
                background-color: #f58426;
                border-radius: 30px;
                margin-top: 15px;
                padding: 17px 40px;
                transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
                width: fit-content;

                &:hover {
                    background-color: #fd5203;

                    div {
                        color: white;
                    }
                }

                div {
                    color: black;
                    font-weight: 600;
                }
            }
        }
    }
}

@media only screen and (min-width: 900px) and (max-width: 1170px) {
    .Home {
        .search-box {
            .flex-row {
                gap: 1%;

                .flex-row-item {
                    width: 24%;

                    .flex-row-center-item {
                        transform: translateY(0);
                    }

                }
            }
        }
    }
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
    .Home {
        .search-box {
            .flex-row {
                gap: 0.83%;

                .flex-row-item {
                    width: 32.5%;
                }
            }
        }
    }
}

@media only screen and (max-width: 769px) {
    .Home {
        overflow-x: hidden;

        .search-box {
            transform: translateY(0);
            border: none;
            margin: 0;

            .flex-row {
                flex-direction: column;

                .flex-row-item {
                    width: 100%;

                    .search-input {
                        -webkit-appearance: none;
                    }
                }
            }
        }

        .main-content {
            margin-top: 30px;

            .route-info {
                .flex-table {
                    flex-direction: column;
                    gap: 30px;
                }
            }

            .comment {
                margin-top: 90px;
            }

            .cards-container {
                .cards-title {
                    font-size: 26px;
                }

                .seperator {
                    width: 80px;
                }

                .cards {
                    flex-direction: column;
                    gap: 30px;

                    .card {
                        width: 100%;

                        .btn {
                            padding: 13px 30px;
                        }
                    }
                }
            }
        }

        .absolute-container {
            background-attachment: scroll;
            height: 60vh;
            justify-content: flex-start;

            .content {
                width: 100%;
                padding: 60px 30px;

                .title {
                    font-size: 26px;
                    max-width: 100%;
                }

                .para {
                    max-width: 100%;
                }

                .btn {
                    padding: 13px 30px;
                }
            }
        }
    }
}

@media only screen and (min-width: 380px) and (max-width: 768) {
    .Home {
        .main-content {
            .route-info {
                .text-2 {
                    font-size: 16px;
                }

                .para {
                    font-size: 14px;
                }

                .para-2 {
                    font-size: 14px;
                }
            }
        }
    }
}

@media only screen and (max-width: 390px) {
    .Home {
        .main-content {
            .route-info {
                .text-2 {
                    font-size: 14px;
                }

                .para {
                    font-size: 12px;
                }

                .para-2 {
                    font-size: 12px;
                    margin-right: 5px;
                }

                .direction-icon {
                    margin: 0 6px;
                }

                svg {
                    height: 18px;
                    width: 18px;
                }
            }
        }
    }
}