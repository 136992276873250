.loader-parent {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.638);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .loader {
    height: 80px;
    width: 80px;
    border: 5px solid #f3f3f3;
    border-left-color: transparent;
    border-radius: 50%;
    animation: loader 1s linear infinite;
  }
  
  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }