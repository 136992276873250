/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

    select,
    textarea,
    input {
        font-size: 16px;

        &:focus {
            -webkit-appearance: none;
            outline: none;
        }
    }
}