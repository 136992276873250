.Header {
    /* background-image: url('../assets/bg.png'); */
    background-image: url('https://takeairdi.com/wp-content/uploads/2024/01/J3500_night-2-1920x0-c-default.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 80vh;
    display: flex;
    flex-direction: column;

    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 50px 8vw 20px 3vw;

        .logo {
            width: 130px;
            height: 45px;
        }

        .nav-links {
            display: flex;
            align-items: center;
            gap: 40px;

            .link {
                font-size: 16px;
                cursor: pointer;

                a {
                    text-decoration: none;
                    color: white;
                }

                .active-link {
                    color: #f58426;
                }
            }
        }
    }

    .top-row {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            color: white;
            font-size: 60px;
            padding: 0px 10px;
            transform: translateY(10%);
        }

        .text {
            color: white;
            font-size: 4rem;
            font-weight: 700;
        }
    }

    .title-wrapper {
        flex: 0.8;
        display: flex;
        align-items: center;
        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            justify-content: left;
            flex: 1;
            color: white;

            .schedule-row {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .direction-icon {
                transform: translateY(2px);
            }

            .text-2 {
                font-size: 2rem;
                font-weight: 700;
            }

            .para {
                color: whitesmoke;
                font-size: 16px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .Header {
        height: 40vh;

        .nav {
            padding: 20px 30px;

            .logo {
                width: 75px;
                height: 30px;
            }

            .nav-links {
                display: none;
            }

            .dropdown {
                .dropdown-btn {
                    display: flex;
                    align-items: center;
                    padding: 6px 9px;
                    border: none;
                    border-radius: 2px;
                    background-color: #f58426;
                }

                .dropdown-list {
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    top: 76.67px;
                    left: 0;
                    width: 100%;
                    background-color: white;
                    z-index: 1;

                    .list-item {
                        padding: 10px 20px;
                        border-bottom: 1px solid #dddddd;

                        a {
                            text-decoration: none;
                            color: black;
                        }
                    }
                }
            }
        }

        .opened-menu {
            background-color: white;
            border-bottom: 1px solid #dddddd;

            .logo {
                filter: none;
            }
        }

        .title {
            /* padding-top: 20px;
            padding-bottom: 50px; */

            .top-row {
                .text {
                    font-size: 30px;
                }
            }

            .text-2 {
                font-size: 24px;
            }

            .para {
                font-size: 12px;
            }

            .info-para {
                font-size: 12px;
            }
        }

    }
}

@media only screen and (min-width: 768px) {
    .Header {
        .nav {
            .dropdown {
                display: none;
            }

        }
    }
}